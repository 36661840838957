<template>
  <div class="tao">
    <div class="tao-content">
      <div class="top">
        <div class="top-left">
          <template v-if="!!token && !!phone">
            <span>{{ phone }}</span>
            <span class="border">|</span>
            <span class="cancel" @click="logOut">注销</span>
          </template>
          <span class="cancel" @click="logIn" v-else>登录</span>
        </div>
        <div class="top-right" v-if="token && phone" @click="goNavigate">
          领取记录 >
        </div>
      </div>
      <div class="box">
        <div class="line">
          <div
            :class="[
              'line-item',
              exchanged_items.includes(item.item_name) ? 'ban' : '',
              index == activeIndex ? 'active' : '',
              {
                opacity:
                  exchanged_items.length > 0 &&
                  !exchanged_items.includes(item.item_name),
              },
            ]"
            v-for="(item, index) in list"
            :key="index"
            @click="handlerActive(index)"
          >
            <div class="top">
              <img class="img" :src="item.item_icon" />
            </div>
            <div class="bottom">
              <div class="name">{{ item.item_name }}</div>
            </div>
          </div>
          <div class="line-item more"></div>
        </div>
      </div>
      <!-- 可领取 -->
      <div class="footer">
        <template v-if="!!token && !!phone">
          <img
            v-if="this.exchanged_items.length === 0"
            class="submit"
            @click="handleSubmit"
            src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/btn.png"
          />
          <!-- 不可领取 -->
          <img
            v-else
            class="submit"
            src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/banbtn.png"
          />
        </template>
      </div>
    </div>
    <tao-dialog v-show="dialog.visibility" :msg="dialog.msg" @close="close">
      <template v-slot:btn v-if="dialog.btnWhether">
        <div class="btns">
          <img
            class="btns-item"
            @click="close"
            src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/dialogbtnFalse.png"
          />
          <img
            class="btns-item"
            @click="doLogout"
            src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/dialogbtnTrue.png"
          />
        </div>
      </template>
    </tao-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { equitylist, claimBenefits, getUser } from "@/api/tao.js";
import taoDialog from "./dialog/popup.vue";
import { isWeixin } from "@/utils/common";

export default {
  components: {
    taoDialog,
  },
  data() {
    return {
      dialog: {
        visibility: false,
        msg: "",
        btnWhether: false,
      },
      activeIndex: undefined,
      list: [],
      code: "",
      isWX: false,
      // 已兑换
      exchanged_items: [],
    };
  },
  computed: {
    ...mapGetters(["token", "phone"]),
  },
  created() {
    document.title = "随心淘";
    this.isWX = isWeixin();
    const code = new URLSearchParams(window.location.search).get("code");
    this.code = code;
    this.getUser_();
    this.getList();
  },

  methods: {
    ...mapActions(["sxt/login"]),
    async getUser_() {
      if (!!this.isWX && !this.token) {
        this["sxt/login"]();
        return;
      }
      if (!this.isWX && !this.token) {
        this.logIn();
        return;
      }
      try {
        const res = await getUser(this.token);
        if (res.code === 0) {
          this.exchanged_items =
            res.user_info.exchanged_items["视听会员"] || [];
        }
      } catch (err) {
        this.logIn();
      }
    },
    // 退出提示
    logOut() {
      this.dialog = {
        visibility: true,
        msg: "您是否要退出登录？",
        btnWhether: true,
      };
    },
    doLogout() {
      this.dialog = {
        visibility: false,
      };
      this.logIn();
    },
    logIn() {
      this.$router.replace("/tao/equityCenter/zfblogin/login");
    },
    close() {
      this.dialog = {
        visibility: false,
        msg: "",
        btnWhether: false,
      };
    },
    goNavigate() {
      this.$router.push({ path: "/tao/equityCenter/record" });
    },
    async handleSubmit() {
      if (this.activeIndex == undefined) {
        this.dialog = {
          visibility: true,
          msg: "请先选择一个要兑换的权益！",
          btnWhether: false,
        };
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "处理中...",
      });
      const item = this.list[this.activeIndex];
      const { code = 0, message = "" } = await claimBenefits(this.token, {
        item_name: item.item_name,
        item_require: "视听会员",
      });
      this.close();
      if (code == 0) {
        this.exchanged_items.push(item.item_name);
        this.activeIndex = undefined;
      }

      this.dialog = {
        visibility: true,
        msg: message,
        btnWhether: false,
      };
    },
    // 获取权益
    async getList() {
      this.list = [];
      const {
        code = 0,
        item = [],
        message = "",
      } = await equitylist({ item_require: "视听会员" });
      if (code == 0) {
        this.list = item;
      } else {
        this.dialog = {
          visibility: true,
          msg: message,
          btnWhether: false,
        };
      }
    },
    close() {
      this.$toast.clear();
      this.dialog = {
        visibility: false,
        msg: "",
        btnWhether: false,
      };
    },
    handlerActive(index) {
      if (this.exchanged_items.length === 0) {
        this.activeIndex = index;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tao {
  background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/available.png")
    center top/contain no-repeat no-repeat;
  background-color: #ff475d;
  padding-bottom: 200px;
  min-height: 100%;

  &-content {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        width: 281px;
        height: 68px;
        line-height: 68px;
        text-align: center;
        border-radius: 0px 23px 34px 0px;
        background-color: rgba(#eb1115, 0.6);
        font-size: 26px;
        color: rgba(#ffffff, 0.7);
        font-weight: normal;

        .cancel {
          color: rgba(#ffffff, 1);
        }

        .border {
          padding: 0 10px;
          color: rgba(#ffffff, 0.5);
        }
      }

      &-right {
        color: #ffffff;
        font-size: 28px;
        padding-right: 26px;
        font-weight: normal;
      }
    }
    .box {
      width: 700px;
      background-color: #ffffff;
      box-shadow: 0px 5px 2px 0px #ff466d, inset 0px 3px 0px 0px #ffffff,
        inset 0px 25px 38px 0px #ffd6e9;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      margin: 166px auto 0;
      padding: 17px 15px;
      box-sizing: border-box;

      .line {
        width: 670px;
        height: 100%;
        background-image: linear-gradient(135deg, #ff5641 0%, #ff9753 93%),
          linear-gradient(#ff8d50, #ff8d50);
        background-blend-mode: normal, normal;
        border-radius: 30px;
        display: flex;
        flex-wrap: wrap;
        padding: 21px 19px 16px 17px;
        box-sizing: border-box;

        &-item {
          background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/itemBG.png")
            center/contain no-repeat no-repeat;
          width: 206px;
          height: 251px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 8px 8px 0;
          text-align: center;
          position: relative;

          &:nth-child(3n) {
            margin-right: 0;
          }

          &.opacity {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: rgba(255, 255, 255, 0.3);
              border-radius: 16px;
              z-index: 2;
            }
          }

          .top {
            height: 154px;

            .img {
              width: 96px;
              height: 96px;
            }
          }

          .top,
          .bottom {
            z-index: 1;
          }

          .bottom {
            .name {
              font-size: 30px;
              color: #af3e51;
              padding: 18px 0 0;
              font-weight: normal;
            }

            .text {
              font-size: 24px;
              margin-top: -6px;
              color: rgba(#af3e51, 0.7);
            }
          }

          &.more {
            background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/more.png")
              center/contain no-repeat no-repeat;
          }

          &.ban {
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              z-index: 1;
              background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/ban2.png")
                center/contain no-repeat no-repeat;
            }
          }

          &.active {
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/itemBG2.png")
                center/contain no-repeat no-repeat;
            }
          }
        }
      }
    }

    .footer {
      width: 700px;
      margin: 0 auto;
      padding: 60px 0 40px;
      box-sizing: border-box;
      background-image: linear-gradient(0deg, #ffdeb7 8%, #ffece1 100%),
        linear-gradient(#ffffff, #ffffff);
      background-blend-mode: normal, normal;
      border-radius: 0px 0px 30px 30px;

      .submit {
        width: 629px;
        height: 104px;
        line-height: 104px;
        display: block;
        margin: 0px auto 0;
      }
    }
  }

  ::v-deep.btns {
    display: flex;
    justify-content: center;

    &-item {
      width: 219px;
      height: 104px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
