var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tao"},[_c('div',{staticClass:"tao-content"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"top-left"},[(!!_vm.token && !!_vm.phone)?[_c('span',[_vm._v(_vm._s(_vm.phone))]),_c('span',{staticClass:"border"},[_vm._v("|")]),_c('span',{staticClass:"cancel",on:{"click":_vm.logOut}},[_vm._v("注销")])]:_c('span',{staticClass:"cancel",on:{"click":_vm.logIn}},[_vm._v("登录")])],2),(_vm.token && _vm.phone)?_c('div',{staticClass:"top-right",on:{"click":_vm.goNavigate}},[_vm._v(" 领取记录 > ")]):_vm._e()]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"line"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
            'line-item',
            _vm.exchanged_items.includes(item.item_name) ? 'ban' : '',
            index == _vm.activeIndex ? 'active' : '',
            {
              opacity:
                _vm.exchanged_items.length > 0 &&
                !_vm.exchanged_items.includes(item.item_name),
            },
          ],on:{"click":function($event){return _vm.handlerActive(index)}}},[_c('div',{staticClass:"top"},[_c('img',{staticClass:"img",attrs:{"src":item.item_icon}})]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.item_name))])])])}),_c('div',{staticClass:"line-item more"})],2)]),_c('div',{staticClass:"footer"},[(!!_vm.token && !!_vm.phone)?[(this.exchanged_items.length === 0)?_c('img',{staticClass:"submit",attrs:{"src":"https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/btn.png"},on:{"click":_vm.handleSubmit}}):_c('img',{staticClass:"submit",attrs:{"src":"https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/banbtn.png"}})]:_vm._e()],2)]),_c('tao-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialog.visibility),expression:"dialog.visibility"}],attrs:{"msg":_vm.dialog.msg},on:{"close":_vm.close},scopedSlots:_vm._u([(_vm.dialog.btnWhether)?{key:"btn",fn:function(){return [_c('div',{staticClass:"btns"},[_c('img',{staticClass:"btns-item",attrs:{"src":"https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/dialogbtnFalse.png"},on:{"click":_vm.close}}),_c('img',{staticClass:"btns-item",attrs:{"src":"https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/dialogbtnTrue.png"},on:{"click":_vm.doLogout}})])]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }